import throttle from "lodash.throttle";
import {WOW} from "wowjs/dist/wow.min";
import refs from "./refs";

const {bodyEl} = refs;

const throttledHandleResize = throttle(handleResize, 200);
let currentBackdrop = null;
export const showBackdrop = (backdrop, hideOnResize = false) => {
    if (!backdrop) {
        return;
    }
    disableBodyScroll();

    backdrop.removeClass("is-hidden");
    backdrop.on("click", handleBackdropClick);
    currentBackdrop = backdrop;
    $(window).on("keydown", handleKeyDown);

    if (hideOnResize) {
        $(window).on("resize", throttledHandleResize);
    }

};

export const hideBackdrop = (backdrop) => {
    if (!backdrop) {
        return;
    }

    enableBodyScroll();

    backdrop.addClass("is-hidden");
	backdrop.off("click", handleBackdropClick);
    backdrop.removeClass("click", handleBackdropClick);
    $(window).off("keydown", handleKeyDown);
    $(window).off("resize", throttledHandleResize);

    currentBackdrop = null;
};

function handleBackdropClick(e) {
    if (e.target === e.currentTarget) {
        hideBackdrop(currentBackdrop);
    }
}

function handleKeyDown(e) {
    if (e.key === "Escape") {
        hideBackdrop(currentBackdrop);
    }
}

function handleResize() {
    const {innerWidth} = window;

    if (innerWidth >= 768) {
        hideBackdrop(currentBackdrop);
    }
}

export function enableBodyScroll() {
    bodyEl.css("overflow-y", "scroll");
}

export function disableBodyScroll() {
    bodyEl.css("overflow-y", "hidden");
}

$("document").ready(function () {
    bodyEl.css("visibility", "visible");

    new WOW().init();
});

document.addEventListener("wpcf7mailsent", () => {
    const thankYouMessages = document.querySelectorAll('.thank-you-message');
    thankYouMessages.forEach(message => {
        message.style.opacity = '1';
        message.style['z-index'] = '3';
		message.style['z-index'] = '9999';
    });
});