import Swiper from "swiper/bundle";
import "swiper/css/bundle";

const swiper = new Swiper(".swiper.advantages-swiper", {
  slidesPerView: 1,
  spaceBetween: 30,
   navigation: {
       nextEl: '#advantages .control.right',
       prevEl: '#advantages .control.left',
     },
    breakpoints: {
        1200: {
          slidesPerView: 4,
        },
        991: {
           slidesPerView: 3,
        },
        576: {
        slidesPerView: 2,
        }
      },
});

const appeal = new Swiper('.swiper.appeal-swiper', {
  slidesPerView: 1,
  breakpoints: {
          1200: {
            slidesPerView: 3,
          },
					991: {
						slidesPerView: 2,
					}
        },
  spaceBetween: 15,
  navigation: {
         nextEl: '#appeal .control.right',
         prevEl: '#appeal .control.left',
       },
});
appeal.slides.forEach((slide, index) => {
  slide.addEventListener('click', () => {
    appeal.slideTo(index);
  });
});



const results = new Swiper('.swiper.results-swiper', {
  slidesPerView: 1.6,
	spaceBetween: 16,
  breakpoints: {
          1200: {
            slidesPerView: 3,
          }, 991: {
						slidesPerView: 2,
						spaceBetween: 30, 
					}
        },
  
  navigation: {
         nextEl: '#results .control.right',
         prevEl: '#results .control.left',
       },
});

