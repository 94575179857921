$(document).ready(function () {
    $(".read-more").click(function () {
        let nextElement = $(this).next(".read-more-wrapper");
        
        if (!nextElement.length) {
            nextElement = $(this).parent().parent().find(".read-more-wrapper").first();
        }

        nextElement.slideToggle(400);

        $(this).text($(this).text() === 'Переглянути більше' ? 'Сховати' : 'Переглянути більше');
    });
});
