import { showBackdrop, hideBackdrop } from "./utils.js";
document.querySelectorAll(".header__anchor-link").forEach((button) => {
  $(button).on("click", () => {
    hideBackdrop($(".backdrop.burger-manu"));
  });
});

document.querySelectorAll(".popup-open").forEach((button) => {
  const form = document.querySelector(".backdrop.window-form");

  button.addEventListener("click", (e) => {
    if (button.classList.contains("window-form") && form) {
      if (button.hasAttribute("data-theme-color")) {
        form.style.setProperty(
          "--theme-color",
          button.getAttribute("data-theme-color")
        );
      }

      if (button.hasAttribute("data-doctor-name")) {
        form.querySelector("input#doctor-name").value =
          button.getAttribute("data-doctor-name");
      }

      showBackdrop($(form), true);
    }

    document.querySelectorAll(".close-button").forEach((closeButton) => {
      $(closeButton).on("click", () => {
        hideBackdrop($(closeButton.closest(".backdrop")));
      });
    });
  });
});

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector("#gyn-ph #hero .title")) {
    document.querySelectorAll('input[name="doctor-name"]').forEach((input) => {
      input.value = document.querySelector("#hero .title").textContent;
    });
  }
});
